export enum TrackingPageType {
  LandingPage = 'lp',
  CmsPage = 'cms',
}

export enum PageTypeName {
  Fallback = 'page',
  HomePage = 'home-page',
  BrandPage = 'brand-page',
  CategoryPage = 'category-page',
  LandingPage = 'landing-page',
}

// Currently used for tracking purpose only
export enum PotionTrackingPageType {
  HOMEPAGE = 'homepage',
  BRAND = 'brand',
  CATEGORY = 'category',
  SEARCH = 'search',
  CART = 'cart',
}
